const topLevelScope = global || window;

if (topLevelScope.crypto) {
  module.exports = topLevelScope.crypto;
} else {
  //eslintdisablenextline @typescripteslint/novarrequires
  const crypto = require('crypto');
  module.exports = {
    getRandomValues: (arr) => crypto.randomBytes(arr.length)
  };
}
