import { createTrackingInstance, TrackingProvider } from '@rategravity/1pt-lib';
import { OwnUpComponentLibraryProvider } from '@rategravity/own-up-component-library';
import React, { ReactNode } from 'react';

export const RootWrapper = ({ element }: { element: ReactNode }) => {
  const trackingInstance = createTrackingInstance({
    url: '/events'
  });
  return (
    <OwnUpComponentLibraryProvider>
      <TrackingProvider value={trackingInstance}>{element}</TrackingProvider>
    </OwnUpComponentLibraryProvider>
  );
};
